"use client";

import dynamic from "next/dynamic";
import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from "react";
import AceEditor, { IAceEditorProps } from "react-ace";
import { insertToolbar } from "../lib/utils";

export interface EditorWithToolbarProps
  extends Omit<IAceEditorProps, "onChange" | "value" | "name"> {
  name: string;
  value: string;
  onChange: (value: string) => void;
  toolbarKey?: "input" | "output";
  testFunction?: () => void;
  mode?: string;
}

const EditorWithToolbar = forwardRef<AceEditor | null, EditorWithToolbarProps>(
  (
    {
      name,
      value,
      onChange,
      toolbarKey,
      testFunction,
      mode = "text",
      ...restProps
    },
    ref,
  ) => {
    const editorRef = useRef<AceEditor | null>(null);
    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const [ready, setReady] = useState(false);

    useImperativeHandle(ref, () => editorRef.current as AceEditor, []);

    useEffect(() => {
      const loadAce = async () => {
        await Promise.all([
          import("ace-builds/src-noconflict/ace"),
          import("ace-builds/src-noconflict/ext-language_tools"),
          import("ace-builds/src-noconflict/theme-github"),
          import("ace-builds/src-noconflict/mode-json"),
          import("ace-builds/src-noconflict/mode-text"),
        ]);
        setReady(true);
      };

      loadAce();
    }, []);

    useEffect(() => {
      if (
        editorRef.current &&
        editorRef.current.editor &&
        toolbarKey &&
        toolbarRef.current
      ) {
        insertToolbar(toolbarRef.current, editorRef, toolbarKey, testFunction);
      }
    }, [toolbarKey, testFunction, ready]);

    if (!ready) return null;

    return (
      <div className="editor-with-toolbar">
        <div
          ref={toolbarRef}
          className="editor-toolbar"
          data-key={toolbarKey}
        />
        <AceEditor
          ref={editorRef}
          mode={mode}
          theme="github"
          name={name}
          fontSize={14}
          showPrintMargin={false}
          showGutter={true}
          highlightActiveLine={true}
          value={value}
          onChange={onChange}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            wrap: true,
          }}
          {...restProps}
        />
      </div>
    );
  },
);

EditorWithToolbar.displayName = "EditorWithToolbar";

export default EditorWithToolbar;
