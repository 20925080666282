import { MutableRefObject } from "react";
import JSONEditor from "jsoneditor";
import AceEditor from "react-ace";

// Utility to clear text in either AceEditor or JSONEditor
export const clearText = (
  editorRef: MutableRefObject<AceEditor | JSONEditor | null>,
) => {
  if (!editorRef.current) return;

  if ("editor" in editorRef.current) {
    editorRef.current.editor.setValue("");
  } else if ("setText" in editorRef.current) {
    editorRef.current.setText("");
  }
};

// Utility to copy text to clipboard
export const handleCopy = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error("❌ Failed to copy to clipboard:", error);
  }
};

// Utility to download a file
export const downloadFile = (text: string, filename: string) => {
  if (!text || !filename) return;

  const blob = new Blob([text], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Utility to paste text from clipboard into the editor
export const handlePaste = async (
  editorRef: MutableRefObject<AceEditor | JSONEditor | null>,
) => {
  try {
    const clipboardText = await navigator.clipboard.readText();
    if (!editorRef.current) return;

    if ("editor" in editorRef.current) {
      editorRef.current.editor.setValue(clipboardText);
    } else {
      editorRef.current.setText(clipboardText);
    }
  } catch (error) {
    console.error("❌ Failed to paste from clipboard:", error);
  }
};

// Utility to create a toolbar button
const createButton = (
  title: string,
  iconSrc: string | null,
  onClick: () => void,
) => {
  const button = document.createElement("button");
  button.classList.add("editor-toolbar-button");
  button.title = title;
  button.onclick = onClick;

  if (iconSrc) {
    const img = document.createElement("img");
    img.src = iconSrc;
    img.alt = title;
    img.width = 24;
    img.height = 24;
    button.appendChild(img);
  } else {
    button.textContent = title;
  }

  return button;
};

// Utility to insert a toolbar into the editor
export const insertToolbar = (
  menuSelector: string | HTMLElement,
  editorRef: MutableRefObject<AceEditor | JSONEditor | null>,
  type: "input" | "output",
  testFunction?: () => void,
) => {
  // Resolve the menu element
  const menuElement =
    typeof menuSelector === "string"
      ? document.querySelector(menuSelector)
      : menuSelector;

  if (!menuElement) {
    console.error("❌ Menu element not found.");
    return;
  }

  // Avoid re-inserting the toolbar if it already exists
  if (menuElement.querySelector(".toolbar")) return;

  // Create the toolbar wrapper
  const toolbarWrapper = document.createElement("div");
  toolbarWrapper.className = "toolbar";

  const toolbarDiv = document.createElement("div");
  toolbarDiv.className = "toolbar-sub";
  toolbarDiv.style.display = "flex";
  toolbarDiv.style.marginLeft = "auto";
  toolbarDiv.style.alignItems = "center";
  toolbarDiv.style.gap = "8px";

  // Add buttons based on the toolbar type
  if (type === "input") {
    if (testFunction) {
      toolbarDiv.appendChild(
        createButton("Test", "/images/bug-icon.svg", testFunction),
      );
    }

    toolbarDiv.appendChild(
      createButton("Clear", "/images/clear-icon.svg", () =>
        clearText(editorRef),
      ),
    );

    toolbarDiv.appendChild(
      createButton("Paste", "/images/paste-icon.svg", () =>
        handlePaste(editorRef),
      ),
    );

    // Add upload button
    const uploadButton = createButton(
      "Upload",
      "/images/upload-icon.svg",
      () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";

        fileInput.onchange = async (event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            const text = await file.text();
            if (editorRef.current) {
              if ("editor" in editorRef.current) {
                editorRef.current.editor.setValue(text);
              } else {
                editorRef.current.setText(text);
              }
            }
          }
        };

        fileInput.click();
      },
    );
    toolbarDiv.appendChild(uploadButton);
  }

  if (type === "output") {
    toolbarDiv.appendChild(
      createButton("Clear", "/images/clear-icon.svg", () =>
        clearText(editorRef),
      ),
    );

    toolbarDiv.appendChild(
      createButton("Copy", "/images/copy-icon.svg", async () => {
        if (editorRef.current) {
          const text =
            "editor" in editorRef.current
              ? editorRef.current.editor.getValue()
              : editorRef.current.getText();
          await handleCopy(text || "");
        }
      }),
    );

    // Add download button
    const downloadButton = createButton(
      "Download",
      "/images/download-icon.svg",
      () => {
        if (editorRef.current) {
          const text =
            "editor" in editorRef.current
              ? editorRef.current.editor.getValue()
              : editorRef.current.getText();
          downloadFile(text || "", "output.txt");
        }
      },
    );
    toolbarDiv.appendChild(downloadButton);
  }

  // Append the toolbar to the menu element
  toolbarWrapper.appendChild(toolbarDiv);
  menuElement.appendChild(toolbarWrapper);
};
