"use client";

import { useEffect, useState, useRef, useMemo } from "react";
import Image from "next/image";
import iconv from "iconv-lite";
import AceEditor from "react-ace";
import { useTranslations } from "next-intl";
import EditorWithToolbar from "@components/EditorWithToolbar";

// ✅ Charset Groups (Organized)
const CHARSET_GROUPS: { [key: string]: string[] } = {
  Popular: ["UTF-8", "ASCII", "ISO-8859-1", "Windows-1252"],
  "ISO-8859 Series": [
    "ISO-8859-2",
    "ISO-8859-3",
    "ISO-8859-4",
    "ISO-8859-5",
    "ISO-8859-6",
    "ISO-8859-7",
    "ISO-8859-8",
    "ISO-8859-9",
    "ISO-8859-10",
    "ISO-8859-13",
    "ISO-8859-14",
    "ISO-8859-15",
    "ISO-8859-16",
  ],
  "Windows Code Pages": [
    "Windows-1250",
    "Windows-1251",
    "Windows-1252",
    "Windows-1253",
    "Windows-1254",
    "Windows-1255",
    "Windows-1256",
    "Windows-1257",
    "Windows-1258",
  ],
  "Chinese & Japanese Encodings": [
    "BIG-5",
    "GB18030",
    "EUC-CN",
    "EUC-JP",
    "EUC-KR",
    "EUC-TW",
    "CP932",
    "CP936",
    "CP950",
    "ISO-2022-JP",
    "ISO-2022-KR",
    "SJIS",
  ],
  "Cyrillic Encodings": ["KOI8-R", "KOI8-U", "CP850", "CP866"],
  "Unicode Encodings": [
    "UTF-16",
    "UTF-16LE",
    "UTF-16BE",
    "UTF-32",
    "UTF-32LE",
    "UTF-32BE",
    "UCS-2",
    "UCS-2LE",
    "UCS-2BE",
    "UCS-4",
    "UCS-4LE",
    "UCS-4BE",
    "UTF-7",
    "UTF7-IMAP",
  ],
  "Other Encodings": [
    "HZ",
    "CP50220",
    "CP50221",
    "CP50222",
    "CP51932",
    "JIS",
    "UHC",
    "ISO-IR-111",
    "ArmSCII-8",
  ],
};

// ✅ Fully Expanded Charset Mappings
const CHARSETS: { [key: string]: string[] } = {
  // Popular
  "UTF-8": ["UTF-8", "utf8"],
  ASCII: ["ASCII", "ANSI_X3.4-1968", "ISO646-US", "us-ascii"],

  // ISO-8859 Series
  "ISO-8859-1": ["ISO-8859-1", "Latin-1", "latin1", "cp819", "IBM819"],
  "ISO-8859-2": ["ISO-8859-2", "Latin-2"],
  "ISO-8859-3": ["ISO-8859-3", "Latin-3"],
  "ISO-8859-4": ["ISO-8859-4", "Latin-4"],
  "ISO-8859-5": ["ISO-8859-5", "Cyrillic"],
  "ISO-8859-6": ["ISO-8859-6", "Arabic"],
  "ISO-8859-7": ["ISO-8859-7", "Greek"],
  "ISO-8859-8": ["ISO-8859-8", "Hebrew"],
  "ISO-8859-9": ["ISO-8859-9", "Latin-5"],
  "ISO-8859-10": ["ISO-8859-10", "Latin-6"],
  "ISO-8859-13": ["ISO-8859-13", "Latin-7"],
  "ISO-8859-14": ["ISO-8859-14", "Latin-8"],
  "ISO-8859-15": ["ISO-8859-15", "Latin-9"],
  "ISO-8859-16": ["ISO-8859-16", "Latin-10"],

  // Windows Code Pages
  "Windows-1250": ["Windows-1250", "cp1250"],
  "Windows-1251": ["Windows-1251", "cp1251", "Cyrillic (Windows)"],
  "Windows-1252": ["Windows-1252", "cp1252", "Western European (Windows)"],
  "Windows-1253": ["Windows-1253", "Greek (Windows)"],
  "Windows-1254": ["Windows-1254", "Turkish (Windows)"],
  "Windows-1255": ["Windows-1255", "Hebrew (Windows)"],
  "Windows-1256": ["Windows-1256", "cp1256", "Arabic (Windows)"],
  "Windows-1257": ["Windows-1257", "Baltic (Windows)"],
  "Windows-1258": ["Windows-1258", "Vietnamese (Windows)"],

  // Chinese & Japanese Encodings
  "BIG-5": ["BIG-5", "Big5"],
  GB18030: ["GB18030", "Chinese (Simplified)"],
  "EUC-CN": ["EUC-CN", "Extended Unix Code for Chinese"],
  "EUC-JP": ["EUC-JP", "Extended Unix Code for Japanese"],
  "EUC-KR": ["EUC-KR", "Extended Unix Code for Korean"],
  "EUC-TW": ["EUC-TW", "Extended Unix Code for Taiwan"],
  CP932: ["CP932", "Shift_JIS (Windows)", "csWindows31J"],
  CP936: ["CP936", "GBK", "windows-936"],
  CP950: ["CP950", "Big5 (Windows)", "windows-950"],
  "ISO-2022-JP": ["ISO-2022-JP", "csISO2022JP"],
  "ISO-2022-KR": ["ISO-2022-KR", "csISO2022KR"],
  SJIS: ["SJIS", "Shift_JIS"],

  // Cyrillic Encodings
  "KOI8-R": ["KOI8-R", "csKOI8R"],
  "KOI8-U": ["KOI8-U", "csKOI8U"],
  CP850: ["CP850", "IBM850", "ibm850"],
  CP866: ["CP866", "IBM866", "ibm866"],

  // Unicode Encodings
  "UTF-16": ["UTF-16", "UTF-16LE", "UTF-16BE"],
  "UTF-32": ["UTF-32", "UTF-32LE", "UTF-32BE"],
  "UCS-2": ["UCS-2", "UCS-2LE", "UCS-2BE"],
  "UCS-4": ["UCS-4", "UCS-4LE", "UCS-4BE"],
  "UTF-7": ["UTF-7"],
  "UTF7-IMAP": ["UTF7-IMAP"],

  // Other Encodings
  HZ: ["HZ", "HZ-GB-2312"],
  CP50220: ["CP50220"],
  CP50221: ["CP50221"],
  CP50222: ["CP50222"],
  CP51932: ["CP51932"],
  JIS: ["JIS", "ISO-2022-JP-2"],
  UHC: ["UHC", "Windows-949", "CP949"],
  "ISO-IR-111": ["ISO-IR-111", "ECMA-Cyrillic"],
  "ArmSCII-8": ["ArmSCII-8", "Armenian Encoding"],
};

// ✅ Charset Examples (No duplicates)
const CHARSET_EXAMPLES: { [key: string]: string } = {
  // Popular Encodings
  "UTF-8": "Hello, 世界! 👋",
  ASCII: "Hello, World!",
  "ISO-8859-1": "Café et été",
  "Windows-1252": "Héllö, Wörld!",

  // ISO-8859 Series
  "ISO-8859-2": "Ahoj světe! (Czech)",
  "ISO-8859-3": "Ħello dinja! (Maltese)",
  "ISO-8859-4": "Sveika pasaule! (Latvian)",
  "ISO-8859-5": "Привет мир! (Russian)",
  "ISO-8859-6": "مرحبا بالعالم! (Arabic)",
  "ISO-8859-7": "Γειά σου κόσμε! (Greek)",
  "ISO-8859-8": "שלום עולם! (Hebrew)",
  "ISO-8859-9": "Merhaba dünya! (Turkish)",
  "ISO-8859-10": "Hallo verden! (Nordic)",
  "ISO-8859-13": "Sveika pasaule! (Baltic)",
  "ISO-8859-14": "Haigh an domhan! (Celtic)",
  "ISO-8859-15": "€ Café € (Euro sign support)",
  "ISO-8859-16": "Bună ziua lume! (Romanian)",

  // Windows Code Pages
  "Windows-1250": "Ahoj světe! (Central European)",
  "Windows-1251": "Привет мир! (Cyrillic)",
  "Windows-1253": "Γειά σου κόσμε! (Greek)",
  "Windows-1254": "Merhaba dünya! (Turkish)",
  "Windows-1255": "שלום עולם! (Hebrew)",
  "Windows-1256": "مرحبا بالعالم! (Arabic)",
  "Windows-1257": "Tere maailm! (Baltic)",
  "Windows-1258": "Xin chào thế giới! (Vietnamese)",

  // Chinese & Japanese Encodings
  "BIG-5": "你好世界! (Traditional Chinese)",
  GB18030: "你好世界! (Simplified Chinese)",
  "EUC-JP": "こんにちは世界! (Japanese)",
  "EUC-KR": "안녕하세요 세계! (Korean)",
  CP932: "こんにちは世界! (Shift_JIS)",
  CP936: "你好世界! (GBK)",
  CP950: "你好世界! (Big5)",
  "ISO-2022-JP": "こんにちは世界! (ISO-2022-JP)",
  "ISO-2022-KR": "안녕하세요 세계! (ISO-2022-KR)",
  SJIS: "こんにちは世界! (Shift_JIS)",

  // Cyrillic Encodings
  "KOI8-R": "Привет мир! (Russian KOI8-R)",
  "KOI8-U": "Привіт світ! (Ukrainian KOI8-U)",
  CP850: "Héllö, Wörld! (OEM Multilingual)",
  CP866: "Привет мир! (OEM Cyrillic)",

  // Unicode Encodings
  "UTF-16": "Hello, 世界! 👋 (UTF-16)",
  "UTF-32": "Hello, 世界! 👋 (UTF-32)",
  "UCS-2": "Hello, 世界! 👋 (UCS-2)",
  "UCS-4": "Hello, 世界! 👋 (UCS-4)",
  "UTF-7": "Hello, 世界! 👋 (UTF-7)",
  "UTF7-IMAP": "Hello, 世界! 👋 (UTF7-IMAP)",

  // Other Encodings
  HZ: "你好世界! (HZ-GB-2312)",
  CP50220: "Japanese (CP50220)",
  CP50221: "Japanese (CP50221)",
  CP50222: "Japanese (CP50222)",
  CP51932: "Japanese (CP51932)",
  JIS: "こんにちは世界! (JIS)",
  UHC: "안녕하세요 세계! (UHC)",
  "ISO-IR-111": "Привет мир! (ISO-IR-111)",
  "ArmSCII-8": "Բարեւ աշխարհ! (Armenian)",
};

// ✅ Translator Function
function createTranslator(dict: Record<string, string>) {
  return (key: string) => dict[key] || key;
}

interface Base64ConverterProps {
  mode?: "encode" | "decode";
  translations: Record<string, string>;
}

export default function Base64Converter({
  mode = "encode",
  translations,
}: Base64ConverterProps) {
  const t = createTranslator(translations);
  const charsetRef = useRef<string>("UTF-8");

  const [inputText, setInputText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [charset, setCharset] = useState("UTF-8");
  const [lineByLine, setLineByLine] = useState(false);
  const [separator, setSeparator] = useState("lf");
  const [showAltNames, setShowAltNames] = useState(false);

  const inputEditorRef = useRef<AceEditor | null>(null);
  const outputEditorRef = useRef<AceEditor | null>(null);

  const iconFilename =
    mode === "encode" ? "icon_base64-encoder.svg" : "icon_base64-decoder.svg";
  const svgPath = `/images/${iconFilename}`;

  // ✅ Handle Charset Example
  const testCharsetExample = () => {
    const resolvedCharset =
      Object.keys(CHARSETS).find((key) =>
        CHARSETS[key].includes(charsetRef.current),
      ) || charsetRef.current;

    let exampleText =
      CHARSET_EXAMPLES[resolvedCharset] || `Sample text for ${resolvedCharset}`;

    if (mode === "decode") {
      try {
        const isSupported = iconv.encodingExists(resolvedCharset);
        const encodedBuffer = isSupported
          ? iconv.encode(exampleText, resolvedCharset)
          : new TextEncoder().encode(exampleText);
        exampleText = btoa(
          String.fromCharCode(...new Uint8Array(encodedBuffer)),
        );
      } catch {
        exampleText = "Error encoding sample text.";
      }
    }

    setInputText(exampleText);
  };

  // ✅ Encode Base64
  const encodeBase64 = () => {
    if (typeof window === "undefined") return;

    try {
      if (!inputText) return;

      let base64Encoded = "";

      if (lineByLine) {
        base64Encoded = inputText
          .split(/\r?\n/)
          .map((line) => {
            try {
              const encodedBuffer = new TextEncoder().encode(line);
              return btoa(
                String.fromCharCode(...new Uint8Array(encodedBuffer)),
              );
            } catch {
              return "[Encoding Error]";
            }
          })
          .join(separator === "crlf" ? "\r\n" : "\n");
      } else {
        try {
          const encodedBuffer = new TextEncoder().encode(inputText);
          base64Encoded = btoa(
            String.fromCharCode(...new Uint8Array(encodedBuffer)),
          );
        } catch {
          base64Encoded = "[Encoding Error]";
        }
      }

      setOutputText(base64Encoded);
    } catch {
      setOutputText(t("errorMessage"));
    }
  };

  // ✅ Decode Base64
  const decodeBase64 = () => {
    if (typeof window === "undefined") return;

    try {
      if (!inputText) return;

      let decodedText = "";

      if (lineByLine) {
        decodedText = inputText
          .split(/\r?\n/)
          .map((line) => {
            try {
              const binaryString = atob(line);
              const bytes = new Uint8Array(
                [...binaryString].map((char) => char.charCodeAt(0)),
              );

              return charset === "UTF-8"
                ? new TextDecoder("utf-8").decode(bytes)
                : iconv.decode(Buffer.from(bytes), charset);
            } catch {
              return "[Decoding Error]";
            }
          })
          .join(separator === "crlf" ? "\r\n" : "\n");
      } else {
        try {
          const binaryString = atob(inputText);
          const bytes = new Uint8Array(
            [...binaryString].map((char) => char.charCodeAt(0)),
          );

          decodedText =
            charset === "UTF-8"
              ? new TextDecoder("utf-8").decode(bytes)
              : iconv.decode(Buffer.from(bytes), charset);
        } catch {
          decodedText = "[Decoding Error]";
        }
      }

      setOutputText(decodedText);
    } catch {
      setOutputText(t("errorMessage"));
    }
  };

  return (
    <div className="page-container tool">
      <h1>
        <Image
          className="tool_title_icon"
          src={svgPath}
          alt="Icon"
          width={24}
          height={24}
        />
        {t(mode === "encode" ? "titleEncode" : "titleDecode")}
      </h1>

      <div className="editor-container">
        {/* Input Section */}
        <div className="editor-box">
          <h2>{t("input")}</h2>
          <EditorWithToolbar
            ref={inputEditorRef}
            name="input-editor"
            value={inputText}
            onChange={(newValue) => setInputText(newValue)}
            toolbarKey="input"
            testFunction={testCharsetExample}
          />
        </div>

        {/* Options Menu */}
        <div className="menu-container">
          <div className="menu-top">
            <div className="arrow-svg">
              <svg width="60" height="20" viewBox="0 0 60 20">
                <line
                  x1="0"
                  y1="10"
                  x2="50"
                  y2="10"
                  stroke="black"
                  strokeWidth="2"
                />
                <polygon points="50,5 60,10 50,15" fill="black" />
              </svg>
            </div>

            <div className="action-sep" />
          </div>
          <div className="menu-elems">
            <button
              className="btn btn-success label-style"
              onClick={() => {
                if (mode === "encode") encodeBase64();
                else decodeBase64();
              }}
              aria-label={mode === "encode" ? "Encode" : "Decode"}
            >
              {t(mode === "encode" ? "encode" : "decode")}
            </button>

            <label className="label-style">
              {t("charset")}:
              <select
                className="select-style"
                value={charset}
                onChange={(e) => {
                  const selectedCharset = e.target.value;
                  setCharset(selectedCharset);
                  charsetRef.current = selectedCharset;
                }}
                id="charset_select"
              >
                {showAltNames
                  ? Object.entries(CHARSETS)
                      .flatMap(([key, aliases]) =>
                        aliases.map((alias) => ({ alias, key })),
                      )
                      .sort((a, b) => a.alias.localeCompare(b.alias))
                      .map(({ alias, key }) => (
                        <option key={alias} value={alias}>
                          {alias} ({key})
                        </option>
                      ))
                  : Object.entries(CHARSET_GROUPS).map(([group, charsets]) => (
                      <optgroup key={group} label={group}>
                        {charsets.map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                      </optgroup>
                    ))}
              </select>
              <input
                type="checkbox"
                checked={showAltNames}
                onChange={() => setShowAltNames(!showAltNames)}
                id="altn_input"
              />
              {t("showAltNames")}
            </label>

            <label className="label-style">
              <input
                type="checkbox"
                checked={lineByLine}
                onChange={() => setLineByLine(!lineByLine)}
                id="linebl_input"
              />
              {t("lineByLine")}
            </label>

            <label className="label-style">
              {t("newlineSeparator")}:
              <select
                value={separator}
                onChange={(e) => setSeparator(e.target.value)}
                id="newl_select"
              >
                <option value="lf">LF (Unix)</option>
                <option value="crlf">CRLF (Windows)</option>
              </select>
            </label>
          </div>
        </div>

        {/* Output Section */}
        <div className="editor-box">
          <h2>{t("output")}</h2>
          <EditorWithToolbar
            ref={outputEditorRef}
            name="output-editor"
            value={outputText}
            onChange={(newValue) => setOutputText(newValue)}
            toolbarKey="output"
          />
        </div>
      </div>
    </div>
  );
}
